import Home from '../components/HomePage.vue';
import GameSchedules from '../components/GameSchedules.vue';
import GameStandings from '../components/GameStandings.vue';
import TeamRosters from '../components/TeamRosters.vue';
import LeagueRules from '../components/LeagueRules.vue';
import ContactUs from '../components/ContactUs.vue';
import GSThursdayA from '../components/GSThursdayA.vue';
import GSThursdayB from '../components/GSThursdayB.vue';
import GSSundayB from '../components/GSSundayB.vue';
import GSSundayC from '../components/GSSundayC.vue';
import StandingsThurA from '../components/StandingsThurA.vue';
import StandingsThurB from '../components/StandingsThurB.vue';
import StandingsSunB from '../components/StandingsSunB.vue';
import StandingsSunC from '../components/StandingsSunC.vue';
import TeamRosterThurA from '../components/TeamRosterThurA.vue';
import TeamRosterThurB from '../components/TeamRosterThurB.vue';
import TeamRosterSunB from '../components/TeamRosterSunB.vue';
import TeamRosterSunC from '../components/TeamRosterSunC.vue';
import HallOfFame from '../components/HallOfFame.vue';
import BasketballCourt from '../components/BasketballCourt.vue';
import DivisionLeadersThurA from '../components/DivisionLeadersThurA.vue';
import DivisionLeadersThurB from '../components/DivisionLeadersThurB.vue';
import DivisionLeadersSunB from '../components/DivisionLeadersSunB.vue';
import DivisionLeadersSunC from '../components/DivisionLeadersSunC.vue';


import { createRouter, createWebHashHistory } from 'vue-router';
const routes = [
  {
    path: '/',
    component: Home,
    meta: {
      title: 'Kontra Sports - Adult Basketball League based in Orange County, California',
      metaTags: [
        {
          name: 'description',
          content: 'Join Kontra Sports, the leading adult basketball league in Seal Beach and Orange County, California. We offer competitive and fun basketball leagues for adults of all skill levels. Sign up today to experience top-tier basketball in Southern California.'
        }
      ]
    }
  },
  {path: '/home', component: Home},
  {path: '/schedules', component: GameSchedules},
  {path: '/standings', component: GameStandings},
  {path: '/rosters', component: TeamRosters},
  {path: '/rules', component: LeagueRules},
  {path: '/contact', component: ContactUs},
  {path: '/thursdayAGameSchedule', component: GSThursdayA},
  {path: '/thursdayBGameSchedule', component: GSThursdayB},
  {path: '/sundayBGameSchedule', component: GSSundayB},
  {path: '/sundayCGameSchedule', component: GSSundayC},
  {path: '/standingsThurA', component: StandingsThurA},
  {path: '/standingsThurB', component: StandingsThurB},
  {path: '/standingsSunB', component: StandingsSunB},
  {path: '/standingsSunC', component: StandingsSunC},
  {path: '/teamRosterThurA', component: TeamRosterThurA},
  {path: '/teamRosterThurB', component: TeamRosterThurB},
  {path: '/teamRosterSunB', component: TeamRosterSunB},
  {path: '/teamRosterSunC', component: TeamRosterSunC},
  {path: '/hallOfFame', component: HallOfFame},
  {path: '/basketballCourt', component: BasketballCourt},
  {path: '/divisionLeadersThurA', component: DivisionLeadersThurA},
  {path: '/divisionLeadersThurB', component: DivisionLeadersThurB},
  {path: '/divisionLeadersSunB', component: DivisionLeadersSunB},
  {path: '/divisionLeadersSunC', component: DivisionLeadersSunC},
]

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

// This callback runs before every route change, including on page load.
router.beforeEach((to, from, next) => {
  // This goes through the matched routes from last to first, finding the closest route with a title.
  const nearestWithTitle = to.matched.slice().reverse().find(r => r.meta && r.meta.title);

  // Find the nearest route element with meta tags.
  const nearestWithMeta = to.matched.slice().reverse().find(r => r.meta && r.meta.metaTags);

  // If a route with a title was found, set the document title to that value.
  if(nearestWithTitle) document.title = nearestWithTitle.meta.title;

  // Remove any stale meta tags from the document using the key attribute we set below.
  Array.from(document.querySelectorAll('[data-vue-router-controlled]')).map(el => el.parentNode.removeChild(el));

  // Skip rendering meta tags if there are none.
  if(!nearestWithMeta) return next();

  // Turn the meta tag definitions into actual elements in the head.
  nearestWithMeta.meta.metaTags.map(tagDef => {
    const tag = document.createElement('meta');

    Object.keys(tagDef).forEach(key => {
      tag.setAttribute(key, tagDef[key]);
    });

    // We use this to track which meta tags we create so we don't interfere with other ones.
    tag.setAttribute('data-vue-router-controlled', '');

    return tag;
  })
  // Add the meta tags to the document head.
  .forEach(tag => document.head.appendChild(tag));

  next();
});

export default router;
